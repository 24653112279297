<template>
  <v-app>
    <v-row justify="center" align="start" class="mt-4">
      <v-col cols="11" class="mx-auto">
        <p class="display-1 mb-12">List of saved claims in a batch</p>
        <p class="font-weight-bold">Approve each claim</p>
        <hr />
        <List render="claims" :data="batch.claims" :titles="titles" />
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
// Component module
import { mapGetters } from "vuex";
import List from "@/views/apps/claim/ListView.vue";

export default {
  name: "claims-list",
  components: { List },
  data() {
    return {
      titles: [
        "Patient Name",
        "Age",
        "Identification Number",
        "Date Created",
        "Approved",
        "Reason",
        "",
      ],
      batch: {},
      page: 0,
    };
  },
  created() {
    const batch_id = Number(this.$route.params.batch_id);
    this.batch = this.getBatchByID(batch_id);
  },
  computed: {
    ...mapGetters({ getBatchByID: "claimModule/getBatchByID" }),
  },
};
</script>

<style scoped>
.position {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}
.bgColor {
  background-color: #eee;
}
</style>
